import React, { useEffect, useMemo, useState, useRef } from "react";
import { Colors } from "common/Colors";
import Button from "components/common-buttons/Button";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea
} from "recharts";

interface GraphInterface {
  timestamp: string;
  [key: string]: number | string;
}

interface MinMaxData {
  minVal: number;
  maxVal: number;
}

interface SyncLineChartProps {
  chartHeight: number;
  graphData: Array<GraphInterface>;
  minMaxData: MinMaxData;
}

const SyncLineChart: React.FC<SyncLineChartProps> = ({
  chartHeight,
  graphData,
  minMaxData
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const colors = [
    Colors.graphBlue,
    Colors.graphGreen,
    Colors.graphOrange,
    Colors.graphMagenta,
  ];

  const [refAreaLeft, setRefAreaLeft] = useState(0);
  const [refAreaRight, setRefAreaRight] = useState(0);
  const [refCoords, setRefCoords] = useState<any>([0, 0]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [ticks, setTicks] = useState<string[]>([]);
  const [xAxisRange, setXAxisRange] = useState<[string, string] | null>(null);
  const [hiddenTrends, setHiddenTrends] = useState<string[]>([]);

  const toggleTrendVisibility = (dataKey: string) => {
    setHiddenTrends((prev) =>
      prev.includes(dataKey)
        ? prev.filter((key) => key !== dataKey) // Show trend if currently hidden
        : [...prev, dataKey] // Hide trend if currently visible
    );
  };

  useEffect(() => {
    if (refCoords[0] !== 0 && refCoords[1] !== 0) {
      let first = filteredData.filter(
        (d: any) => d.timestamp === refCoords[0]
      )[0];
      let firstIdx = filteredData.indexOf(first);
      let last = filteredData.filter(
        (d: any) => d.timestamp === refCoords[1]
      )[0];
      let lastIdx = filteredData.indexOf(last);
      if (firstIdx > -1 && lastIdx < filteredData.length) {
        let newData = filteredData.slice(firstIdx, lastIdx);
        setFilteredData(newData);
        setRefAreaLeft(0);
        setRefAreaRight(0);
        setRefCoords([0, 0]);
      }
    }
  }, [refCoords]);

  useEffect(() => {
    if (graphData.length > 0) {
      const cleanedData = graphData
        .map((d) => {
          const match = d.timestamp.match(/(\d+)\/(\d+)\/(\d+) (\d+):(\d+):(\d+) (AM|PM)/);
          if (!match) {
            // console.error(`Invalid timestamp format: ${d.timestamp}`);
            return null;
          }
          let [_, month, day, year, hours, minutes, seconds, period] = match;
          const monthNum = Number(month);
          const dayNum = Number(day);
          const yearNum = Number(year);
          let hoursNum = Number(hours);
          const minutesNum = Number(minutes);
          const secondsNum = Number(seconds);

          if (period === "PM" && hoursNum !== 12) hoursNum += 12;
          if (period === "AM" && hoursNum === 12) hoursNum = 0;

          const parsedDate = new Date(yearNum, monthNum - 1, dayNum, hoursNum, minutesNum, secondsNum);

          if (isNaN(parsedDate.getTime())) {
            // console.error(`Invalid timestamp: ${d.timestamp}`);
            return null;
          }
          const formattedData: { [key: string]: number | string } = {
            ...d,
            timestamp: parsedDate.getTime(),
          };
          Object.keys(d).forEach((key) => {
            if (key !== "timestamp") {
              formattedData[key] = isNaN(parseFloat(d[key] as string))
                ? 0
                : parseFloat(d[key] as string);
            }
          });
          return formattedData;
        })
        .filter(Boolean);
      setFilteredData(cleanedData);
    }
  }, [graphData]);

  useEffect(() => {
    if (filteredData.length > 0) {
      const first = filteredData[0].timestamp as string;
      const last = filteredData[filteredData.length - 1].timestamp as string;
      if (filteredData.length > 1) {
        setXAxisRange([first, last]);
        setTicks([first, last]);
      } else {
        setXAxisRange([first, first + 1]);
        setTicks([first]);
      }
    }
  }, [filteredData]);

  const handleMouseDown = (event: any) => {
    if (event?.activeLabel) {
      setRefCoords([]);
      setRefAreaLeft(event.activeLabel);
      return;
    } else {
      return event;
    }
  };

  const handleMouseUp = (event: any) => {
    if (refAreaRight && refAreaLeft) {
      let epochLeft: any = new Date(refAreaLeft);
      epochLeft = epochLeft.getTime();
      let epochRight: any = new Date(refAreaRight);
      epochRight = epochRight.getTime();
      if (epochRight > epochLeft) {
        setRefCoords([refAreaLeft, refAreaRight]);
      } else {
        setRefCoords([refAreaRight, refAreaLeft]);
      }
    }
    setRefAreaLeft(0);
    setRefAreaRight(0);
    return event;
  };

  const handleMouseLeave = (event: any) => {
    setRefAreaLeft(0);
    setRefAreaRight(0);
  };

  const handleMouseMove = (event: any) => {
    if (refAreaLeft) {
      const start = event?.activePayload[0].payload.start;
      const end = event?.activePayload[0].payload.end;
      const refRight = refAreaLeft < end ? end : start ?? event.activeLabel;
      setRefAreaRight(refRight);
    }
    return event;
  };

  const resetChart = () => {
    const cleanedData = graphData
      .map((d) => {
        const match = d.timestamp.match(/(\d+)\/(\d+)\/(\d+) (\d+):(\d+):(\d+) (AM|PM)/);
        if (!match) {
          // console.error(`Invalid timestamp format: ${d.timestamp}`);
          return null;
        }

        let [_, month, day, year, hours, minutes, seconds, period] = match;

        const monthNum = Number(month);
        const dayNum = Number(day);
        const yearNum = Number(year);
        let hoursNum = Number(hours);
        const minutesNum = Number(minutes);
        const secondsNum = Number(seconds);
        if (period === "PM" && hoursNum !== 12) hoursNum += 12;
        if (period === "AM" && hoursNum === 12) hoursNum = 0;
        const parsedDate = new Date(yearNum, monthNum - 1, dayNum, hoursNum, minutesNum, secondsNum);

        if (isNaN(parsedDate.getTime())) {
          // console.error(`Invalid timestamp: ${d.timestamp}`);
          return null;
        }
        const formattedData: { [key: string]: number | string } = {
          ...d,
          timestamp: parsedDate.getTime(),
        };
        Object.keys(d).forEach((key) => {
          if (key !== "timestamp") {
            formattedData[key] = isNaN(parseFloat(d[key] as string))
              ? 0
              : parseFloat(d[key] as string);
          }
        });
        return formattedData;
      })
      .filter(Boolean);
    setFilteredData(cleanedData);
    setRefAreaLeft(0);
    setRefAreaRight(0);
    setRefCoords([0, 0]);
  };

  const CustomLegend = (props: any) => {
    const { payload } = props;
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          padding: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {payload.map((entry: any, index: number) => (
          <div
            key={`legend-item-${index}`}
            onClick={() => toggleTrendVisibility(entry.dataKey)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: hiddenTrends.includes(entry.dataKey)
                ? "lightgray"
                : entry.color,
              color: hiddenTrends.includes(entry.dataKey) ? "darkgray" : "white",
              borderRadius: "20px",
              padding: "5px 10px",
              fontSize: "12px",
              fontWeight: "bold",
              cursor: "pointer",
              opacity: hiddenTrends.includes(entry.dataKey) ? 0.6 : 1,
              textDecoration: hiddenTrends.includes(entry.dataKey)
                ? "line-through"
                : "none",
              minWidth: "100px",
            }}
          >
            {entry.value}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div ref={chartRef} style={{ userSelect: "none" }}>
      <Button
        type="button"
        color="defaultTheme"
        onClick={() => resetChart()}
        style={{ margin: ".5rem 0" }}
      >
        Zoom Out
      </Button>
      {filteredData.length > 0 ? (
        <ResponsiveContainer width="100%" height={chartHeight}>
          <LineChart
            data={filteredData}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={() => {
              setRefAreaLeft(0);
              setRefAreaRight(0);
            }}
            onMouseUp={handleMouseUp}
            syncId="anyId"
            margin={{
              top: 20,
              right: 50,
              left: 20,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {xAxisRange && (
              <XAxis
                dataKey="timestamp"
                domain={['dataMin', 'dataMax']}
                scale="time"
                type="number"
                tickFormatter={(value) => {
                  const date = new Date(value);
                  const options: Intl.DateTimeFormatOptions = {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,
                  };
                  return date.toLocaleString(undefined, options);
                }}
                ticks={ticks}
              />
            )}
            <YAxis
              type="number"
              tickFormatter={(value: number) => {
                return value.toFixed(2);
              }}
              domain={[
                (min: number) => {
                  if (isFinite(min)) {
                    // Changed formula for issue #274
                    var decimals = 0;
                    const range = Math.abs(minMaxData.maxVal - minMaxData.minVal);
                    var maxsign = 1;
                    var minsign = 1;

                    if (minMaxData.maxVal !== 0) {
                      maxsign = Math.sign(minMaxData.maxVal);
                    } else {
                      maxsign = 1;
                    }
                    if (minMaxData.minVal !== 0) {
                      minsign = Math.sign(minMaxData.minVal);
                    } else {
                      minsign = 1;
                    }
                    if (range <= 1) {
                      decimals = 2;
                    } else if (range > 1 && range <= 5) {
                      decimals = 1;
                    } else if (range > 5) {
                      decimals = 0;
                    }
                    const newmin = minsign * (Math.abs(minMaxData.minVal) - 0.2 * minsign * range);
                    const truncmin = parseFloat(newmin.toFixed(decimals));
                    return truncmin;
                  } else {
                    return 0;
                  }
                },
                //Changed domain max value formula for issue #117
                (max: number) => {
                  if (isFinite(max)) {
                    var decimals = 0;
                    const range = Math.abs(minMaxData.maxVal - minMaxData.minVal);
                    var maxsign = 1;
                    var minsign = 1;
                    if (minMaxData.maxVal !== 0) {
                      maxsign = Math.sign(minMaxData.maxVal);
                    } else {
                      maxsign = 1;
                    }
                    if (minMaxData.minVal !== 0) {
                      minsign = Math.sign(minMaxData.minVal);
                    } else {
                      minsign = 1;
                    }
                    if (range <= 1) {
                      decimals = 2;
                    } else if (range > 1 && range <= 5) {
                      decimals = 1;
                    } else if (range > 5) {
                      decimals = 0;
                    }
                    const newmax = maxsign * (Math.abs(minMaxData.maxVal) + 0.2 * maxsign * range);
                    const truncmax = parseFloat(newmax.toFixed(decimals));
                    return truncmax;
                  } else {
                    return 1;
                  }
                }
              ]}
            />
            <Tooltip
              labelFormatter={(value) => new Date(value).toLocaleString()}
            />
            <Legend content={<CustomLegend />} />
            {Object.keys(filteredData[0] || {}).map(
              (key, i) =>
                key !== "timestamp" && (
                  <Line
                    key={key}
                    type="monotone"
                    dataKey={key}
                    stroke={colors[i % colors.length]}
                    dot={false}
                    activeDot={{ r: 8 }}
                    hide={hiddenTrends.includes(key)}
                  />
                )
            )}
            {refAreaLeft && refAreaRight ? (
              <ReferenceArea
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
            {refCoords.length > 0 && (
              <ReferenceArea
                x1={refCoords[0]}
                x2={refCoords[1]}
                strokeOpacity={0.3}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <h1>No Graph Data.</h1>
      )}
    </div>
  );
};

export default SyncLineChart;
