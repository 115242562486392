import { companyDarkColor } from "custom/customizations";

export const Colors: { [key: string]: string } = {
  BasinRed: companyDarkColor, //updated color from #6D1919 to #253746 for issue #204
  MainColor: companyDarkColor,
  Red: "#FF0000",
  Pink: "#ED254E",
  LightGray: "#DDDDDD",
  Gray: "#A6A6A6",
  Green: "#00D160",
  White: "#FFFFFF",
  Yellow: "#FFC529",
  Black: "#000",
  BasinBlue: "#56CCF2",
  BasinDarkBlue: "#067BC2",
  BasinPurple: "#BB6BD9",
  Orange: "#FF6900",
  DashboardRed: "#ed254e",
  DashboardYellow: "#fcc42c",
  DashboardGreen: "#00D160",
  DashboardBlue: "#047cc4",
  DashboardDarkBlue: companyDarkColor,
  DefaultTheme: companyDarkColor,
  LightRed: "#ed254e",
  graphBlue: "#0072B2",
  graphLightBlue: "#56B4E9",
  graphGreen: "#009E73",
  graphYellow: "#0072B2",
  graphOrange: "#D55E00",
  graphMagenta: "#CC79A7",
};
